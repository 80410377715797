:root {
  --mainColor: #922c88;
  --secondary: #4556ac;
  --hoverColor: #73236b;
}

body,
html,
#root {
  background-color: #f8f8f8;
  height: 100vh;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: none;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: none;
}

span.side-navigation-panel-select-inner-option-text {
  color: white;
}

nav.side-navigation-panel {
  background-color: #55495f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #55495f;
  position: fixed;
  top: 0;

  z-index: 1000;
  height: 100%;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option:hover {
  background-color: #f77d7f !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: #f77d7f !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option:hover {
  background-color: #f77d7f !important;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  background-color: #f77d7f !important;
}

.side-navigation-panel-select-option-text {
  color: white;
}

.side-navigation-panel ul {
  margin-bottom: 1em !important;
  /* margin-top: 1em !important; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.side-navigation-panel,
.side-navigation-panel::after,
.side-navigation-panel::before {
  max-width: 300px;
  min-width: 300px;
}

/*Card */

.mini-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 15em;
  background: #fff;
  padding: 1em 1em;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 8px #b3b3b870);
}

.quill {
  margin-top: 100px;
  margin-bottom: 100px;
}
.ql-editor {
  min-height: 200px;
}

.mini-card h4 {
  color: #dcdada;
}

.mini-card img {
  height: 32px;
  width: 32px;
}

.card-list-item {
  border-radius: 15px !important;
  border: none;
  height: 80px;
  filter: drop-shadow(0px 0px 8px #b3b3b870);
}

.business-header {
  /* clip-path: inset(10px 20px 30px 40px); */
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.addNewBtn {
  border-radius: 30px;
  background-color: #922c88;
  color: white;
  /* padding: 5px 35px; */
  width: 200px !important;
  height: 40px;
  border: none;
  transition: all 0.3s;
}

.addNewBtn:hover {
  background-color: #5a1553;
}

.actionsBtn {
  border-radius: 30px;
  background-color: white;
  color: #922c88;
  /* padding: 5px 35px; */
  width: 200px;
  height: 40px;
  border: 1px solid #922c88;
  transition: all 0.3s;
}

#dropdown-basic {
  border-radius: 30px;
  background-color: white;
  color: #922c88;
  /* padding: 5px 35px; */
  width: 200px;
  height: 40px;
  border: 1px solid #922c88;
  transition: all 0.3s;
  outline: none;
}

#dropdown-basic:focus {
  outline: none;
}

#dropdown-basic:hover {
  background-color: #922c88;
  color: white;
}

.editViewIcons {
  transition: all 0.3s;
  cursor: pointer;
}

.editViewIcons:hover {
  color: #922c88;
}

.searchBar {
  border-radius: 20px;
  width: 300px !important;
  outline: none;
  border: 1px solid #555;
  margin: 0 0 20px 12px;
}

.thumbnail-container {
  margin: 0 auto;
  width: 400px;
}

.thumbnail-container > * {
  height: 70px;
  width: auto;
}

.form-input-field {
  width: 100%;
  margin-top: 0;
  border-radius: 5px;
  border: 1px solid #aaa;
  height: 35px;
}

.form-input-container {
  margin: 10px auto;
  width: 95% !important;
}

.form-input-label {
  font-size: 16px;
  margin-bottom: 0;
}

/* .form-input-btn {
  width: 200px !important;
  height: 50px;
  margin: 0 auto;
} */

.form-view {
  position: absolute;
  height: 1500px;
  /* border: 1px solid red; */
  min-width: 40%;
  max-width: 40%;
  right: 0;
  top: 8.5%;
  background: #fff;
  z-index: 1000;
  display: none;
}

.visible {
  display: block;
}

.main-card {
  background: #fff;
  border-radius: 10px;
  padding: 1.5em 0em;
  filter: drop-shadow(0px 0px 8px #b3b3b870);
}

.main-card img {
  border-radius: 10px;
}

.main-card .card-title {
  font-size: 20px;
  margin: 0 0 15px 0;
}

.main-card-body {
  max-height: 35vh;
  overflow-y: scroll;
}

.main-card-body-small {
  max-height: 18vh;
  overflow-y: scroll;
  padding: 1em 0;
}

.main-card-body-list ul {
  display: grid;
  grid-template-columns: (auto-fill, minmax(50%, 3fr));
}

.main-card-body-table table tr,
th {
  border: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 6px;
}

.main-card h2 {
  color: #dcdada;
}

/** Login **/

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.login-form-fields {
  display: flex;
  flex-direction: column;
}

.login-form-fields input {
  margin: 10px 0;
  border-radius: 10px;
  padding: 10px 15px;
  border-color: #ededed;
}

.login-form-fields label {
  margin: 15px 0 0 0;
}

.login-details {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ededed;
  padding: 1em 2em;
  border-left: none;
  display: flex;
  justify-content: center;
}

.login-img {
  background: url("https://images.all-free-download.com/images/graphicthumb/office_work_background_working_man_desk_icons_decor_6837962.jpg");
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.login-form {
  width: 80%;
}

.selected-shipping-country-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  display: flex;
}

.selected-payment-container {
  margin: 10px 0;
  display: flex;
}

.selected-shipping-country {
  width: 100px;
  height: 30px;
  background-color: #ddd;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.selected-payment {
  width: 100px;
  height: 30px;
  background-color: #ddd;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  text-align: center;
  padding: 0 10px;
  cursor: pointer;
}

.selected-shipping-country:hover {
  background-color: #aaa;
}

/**  Dashboard **/
.content {
  padding: 0em 2em;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background: #f8f8f8;
  /* border-top-left-radius: 52px;
  border-bottom-left-radius: 52px; */
  position: relative;
  right: 60px;
  margin-left: 400px;
}

.card-title {
  margin-bottom: 0.5em !important;
}

.min-width-zero {
  min-width: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.dashboardContainer {
  /* height: 92vh; */
  /* background-color: #fff;
  box-shadow: 0px 3px 6px #00000014;

  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
  z-index: 1;
  top: 2px;
  border-radius: 10px;
  position: relative; */
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.for-slide-bar {
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
  z-index: 1;
  top: 2px;
  border-radius: 10px;
  position: relative;
}

.sub-nav {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000014;
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
  z-index: 1;
  top: 2px;
  border-radius: 10px;
  position: relative;
  display: none;
  position: absolute;
  background: white;
  left: 100%;
  border: hidden;
  height: 100%;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.dashboardContainer li {
  padding: 5%;
}

.dashboardContainer li.active {
  border-left: 5px solid #922c88 !important;
  background-color: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.dashboardContainer li.active a {
  text-decoration: none;
}

.badge {
  background-color: #922c88 !important;
}

.for-img-and-link {
  margin: auto;
  /* display: block; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.for-img-and-link img {
  width: 29%;
  margin: 18px 0px;
}

.for-img-and-link a {
  color: black !important;
  font-weight: normal;
  text-decoration: none;
}

/** Header **/

.header nav {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000014;
  height: 80px;
}

.user-icon {
  border-radius: 50%;
  width: 100px;
  left: 30%;
  position: relative;
  margin-bottom: 50%;
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: unset !important;
}

.side-navigation-panel-select-option-icon
  .side-navigation-panel-select-option-icon-chevron-up {
  color: white;
}

.right-part-of-navbar {
  position: absolute;
  width: 22%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-tab li {
  flex: 1;
}

.full-tab button {
  width: 100%;
}

.list-container {
  max-height: 73.6vh;
  overflow-y: scroll;
}

.right-part-of-navbar a {
  font-size: 13px;
  margin: 0;
  padding: 0;
  padding-right: 2%;
}

.logo-navbar {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  position: relative;
  writing-mode: lr;
  left: -20%;
}

/** Login Page **/

/*** simple-line-icon.css ***/

.main-col-details {
  border-bottom: 2px solid #922c88;
}

.userDetail {
  background-color: #fff;
  border-radius: 5px;
  padding: 2% 5%;
  margin-top: 20px !important;
}

.userDetail .row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.userDetail .col-5 {
  /* background-color: #f8f8f8; */
  margin: 8px;
  padding: 1%;
  border-radius: 12px;
  overflow: hidden;
}

.main-col-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backButton {
  color: #f77d7f;
}

.backButton:hover {
  color: var(--hoverColor);
}
.userDetail input {
  border-color: var(--mainColor);
  padding: 1.5%;
  border-radius: 5px;
  height: 50px;
}

.userDetail-edit .col-5 {
  background-color: unset;
}

.userDetail-edit button {
  /* display: block; */
  margin-top: 30px !important;
  width: 400px !important;
  height: 54px;
  font-size: 22px;
  float: right;
}

.closeButton:hover {
  color: white;
  background-color: var(--mainColor);
  border-radius: 50%;
  cursor: pointer;
}

.card-number-orders-list {
  overflow: hidden;
}
.side-navigation-panel ul {
  /* width: 85%; */
}

.single-product-list-div {
  background-color: white;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  margin-top: 10px;
}

.greetingCard {
  background-color: #f77d7f;
  padding: 2em;
  border-radius: 28px;
  position: relative;
}

.greetingCard img {
  position: absolute;
  max-height: 250px;
  right: -3em;
  top: -4em;
}

.greetingCardSmall {
  background-color: #f77d7f;
  padding: 2em;
  border-radius: 28px;
  position: relative;
}

.greetingCardSmall img {
  position: absolute;
  max-height: 250px;
  right: -3em;
  top: -2em;
}

.infoCard {
  padding: 2em;
  margin: 1em 0;
  border-radius: 28px;
  border: 1px solid #ededed;
}

.infoCard h2 {
  font-size: 38px;
  font-weight: bolder;
}

.infoCard h4 {
  font-weight: 400;
}

.performanceCard {
  background-color: #fecb63;
  border-radius: 28px;
  padding: 2em;
  max-height: 95vh;
}

.clientsCard img {
  max-height: 70px;
  min-height: 70px;
}

.performanceCardSmall {
  background-color: #fff;
  border-radius: 18px;
  margin: 0.5em 0;
  padding: 1.5em 2em;
}

.performanceCardSmall hr {
  height: 10px;
  border-radius: 18px;
}

.default-line-height {
  line-height: 32px;
  letter-spacing: 1px;
}

.product-dynamic-item-container {
  position: relative;
  min-height: 200px;
}

.product-dynamic-container h4 {
  font-size: 18px;
  font-weight: bold;
}

.product-dynamic-item-container input {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 5px;
  height: 33px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.product-dynamic-item-container textarea {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 5px;
  min-height: 100px;
}

.product-package-container h4 {
  font-size: 18px;
  font-weight: bold;
}

.product-package-item-container {
  position: relative;
  min-height: 50px;
}

.product-package-item-container h4 {
  font-size: 18px;
  font-weight: bold;
}

.product-package-item-container input {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 5px;
  height: 33px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.product-plus-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.single-product-list-div hr {
  height: 1px;
  margin: 0;
  margin-bottom: 22px !important;
  width: 93%;
  display: block;
  margin: auto;
  margin-top: 15px !important;
}

.for-content {
  padding: 4%;
}

.price-and-inventory {
  display: flex;
  justify-content: space-between;
}

.item-inventory {
  color: gray;
  font-weight: 600;
}
.product-price-single-product {
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
}

.product-price-single-product span {
  color: #ffca63;
  background-color: rgba(255, 202, 99, 0.2);
  margin-left: 15px;
  padding: 2% 8%;
  border-radius: 4px;
}

.single-product-list-div img {
  border-radius: 20px;
}

.new-product-name-input {
  background-color: white;
  padding: 20px;
  font-size: 22px;
  border: none;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
  font-weight: bold;
  height: 50px;
  /* box-sizing: border-box; */
}

.new-product-name-input:focus {
  outline: none;
  border: 1px solid #ddd;
}

.new-product-price-container {
  background-color: #def2ef;
  border: none;
  border-radius: 30px;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.currency-input-field {
  height: 50px;
  width: 80px;
  background: "#ddd";
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.product-currency-selector {
  width: 80px;
  height: 40px;
  margin: 5px 0;
  background-color: #5cbcb1;
  border-radius: 25px;
  position: absolute;
  z-index: 9;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.product-price-input {
  background-color: transparent;
  color: #5cbcb1;
  border: none;
  outline: none;
  width: 100px;
  margin-right: 30px;
  margin-left: 30px;
  font-weight: bold;
  font-size: 24px;
}

.short-description-textarea {
  width: 100%;
  height: 90px;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.short-description-textarea:focus {
  outline: 1px solid #ddd;
}

.new-product-input {
  width: 100px;
  background-color: white;
  border: none;
  border-radius: 5px;
  height: 33px;
}

.new-product-input:focus {
  outline: 1px solid #ddd;
}

.product-inventory-container {
  display: flex;
  margin-bottom: 20px;
}

.product-inventory-container p {
  margin: 0 20px 0 0;
}

.product-select-container {
  display: flex;
  margin-bottom: 20px;
}

.product-select-container p {
  margin: 0 20px 0 0;
}

#categories {
  background-color: white;
  border: none;
  border-radius: 5px;
  height: 33px;
  width: 180px;
}

#categories select {
  border: 1px solid #ddd;
}

#variant {
  background-color: white;
  border: none;
  border-radius: 5px;
  height: 33px;
  width: 180px;
}

#variant select {
  border: 1px solid #ddd;
}

.product-type-container h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.detailed-description-container h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.detailed-description-container textarea {
  width: 100%;
  height: 180px;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.product-edit-ft-image-container {
  object-fit: contain;
  height: 100%;
  width: auto;
}

.product-ft-image-container img {
  max-width: 100%;
  max-height: 100%;
}

/* max-width:100%;
max-height:100%; */

.detailed-description-container textarea:focus {
  outline: 1px solid #ddd;
}

.product-shipping-container {
  min-height: 200px;
  min-width: 400px;
  margin-bottom: 20px;
}

.product-shipping-container input {
  margin-right: 10px;
}

.product-shipping-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
}

.product-shipping-container h4 {
  font-size: 18px;
  font-weight: bold;
}

.admin-panel-product-img-container {
  height: 200px;
  width: 100%;
}

.admin-panel-product-img-container img {
  max-height: 100%;
  max-width: 100%;
}

.drop-zone {
  width: 120px;
  height: 120px;
  border: 1px dashed #777;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  margin-top: 10px;
}

.drop-zone p {
  margin: 0;
}

.drop-zone-uploaded {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.drop-zone-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 20px;
}

.drop-zone-uploaded img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: all 0.3s;
}

.drop-zone-uploaded img:hover {
  transform: scale(1.2);
}

.generic-featured-image {
  color: #ddd;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.click-to-choose-featured-img {
  text-align: center;
  position: absolute;
}

.choose-featured-img-close-btn {
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.choose-featured-img-close-btn:hover {
  transform: scale(1.2);
}

.page-item .page-link {
  color: rgba(0, 0, 0, 0.7);
}

.page-item .page-link:hover {
  background-color: rgba(190, 189, 189, 0.7);
  color: rgba(0, 0, 0, 0.7);
}

.page-item.active .page-link {
  background-color: #b8385b !important;
  border-color: #b8385b !important;
  outline: none;
}

.shipping-select-btn {
  width: 80px;
  height: 40px;
  margin-left: 20px;
  border-radius: 25px;
  z-index: 9;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.shipping-select-disabled {
  background-color: #ddd;
}
.shipping-select-yes {
  background-color: #5cbcb1;
}
.shipping-select-no {
  background-color: #b8385b;
}

.product-type-input {
  width: 78px;
  height: 34px;
  background-color: white;
}

.product-type-input-container p {
  text-align: center;
  margin-top: 10px;
}

.save-btn {
  background-color: #5cbcb1;
  border: none;
  width: 150px;
}

.save-btn:hover {
  background-color: #429e93;
  border: none;
}

.save-btn:focus {
  background-color: #429e93;
  outline: none;
}

.preview-btn {
  background-color: #d69299;
  border: none;
  width: 100px;
  margin-left: 20px;
}

.preview-btn:hover {
  background-color: #b87077;
}

.discard-btn {
  background-color: #8f8f8f;
  border: none;
  width: 100px;
  margin-left: 20px;
}

.discard-btn:hover {
  background-color: #666464;
}

.for-detail-view {
  position: absolute;
  width: 40px;
  height: 25px;
  background-color: white;
  right: 4%;
  top: 4%;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.for-detail-view p {
  margin: 0 !important;
  font-size: 2em;
  position: relative;
  top: -9px;
}

.go-back-div {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.go-back-div p {
  margin: 0 !important;
  margin-left: 25px !important;
}

.go-back-div a {
  text-decoration: none;
}

.for-detali-price-div {
  background-color: rgba(255, 202, 99, 0.3);
  font-size: 30px;
  font-weight: 600;
  text-align: right;
  padding: 2% 3%;
  border-radius: 15px !important;
}

.for-detali-price-div p {
  margin: 0 !important;
}

.div-for-short-description p {
  margin: 0 !important;
}

.div-for-short-description {
  border: 2px solid gray;
  padding: 2% 3%;
  margin-top: 20px !important;
  border-radius: 15px;
  font-size: 19px;
}

.sala-price-div {
  margin-top: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sala-price-div h3 {
  font-weight: 600;
  color: gray;
  width: 28%;
}
.sala-price-div h5 {
  text-align: right;
  background-color: #f77d7f;
  padding: 2% 3%;
  border-radius: 15px;
  color: rgba(255, 202, 99, 1);
  font-size: 26px !important;
  width: 68%;
}

.main-product-descriptio {
  background-color: #fecb63;
  color: white;
  font-size: 20px;
  border-radius: 15px;
  padding: 2%;
}

.performanceCardSmall img {
  max-height: 70px;
  min-height: 70px;
}

.side-navigation-panel-select:last-of-type {
  margin-top: 50%;
}
